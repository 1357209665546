import axios from "axios";
import {
  API_URL,
  LOGGED_IN_USER_INFO,
  ERROR_MESSAGE_UNKNOWN,
  SHOW_LOADER,
  OKTA_TOKEN_INFO,
} from "constants/common";
import { toast } from "react-toastify";
import HistoryService from "./HistoryService";
import store from "redux/store";
import { showBanner } from "redux/actions/bannerActions";
import { METHOD_SAASLAB_VALIDATE, METHOD_SSO_VERIFY_TOKEN } from "constants/login";
import _ from "lodash";

const historyServiceObj = new HistoryService();

function getCookieValue(cookieName) {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null; // Return null if cookie is not found
}

function globalSpinner(payload) {
  return {
    type: SHOW_LOADER,
    payload,
  };
}

const NetworkService = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

//request interceptor//
const requestHandler = (request) => {
  request.url !== "session/" &&
    request.method !== "post" &&
    store.dispatch(globalSpinner(true)); //show global spinner
  request.url.includes("session/status/") &&
    request.method === "get" &&
    store.dispatch(globalSpinner(false)); //show global spinner
    request.url.includes("session/used-time/") &&
    request.method === "patch" &&
    store.dispatch(globalSpinner(false)); //show global spinner

  const userInfo = _.isEmpty(JSON.parse(localStorage.getItem(OKTA_TOKEN_INFO)))
    ? JSON.parse(sessionStorage.getItem(LOGGED_IN_USER_INFO))
    : JSON.parse(localStorage.getItem(OKTA_TOKEN_INFO));
  if (userInfo?.access_token || userInfo?.accessToken) {
    const skipTokenMethods = [METHOD_SSO_VERIFY_TOKEN,METHOD_SAASLAB_VALIDATE]; //methods where we want to skip the bearer
    if (!skipTokenMethods.includes(request.url)) {
      request.headers["Authorization"] =
        "Bearer " +
        (userInfo.access_token || userInfo?.accessToken.accessToken);
    }else{
      request.headers["X-CSRFToken"] = getCookieValue("csrftoken")
    }

    if (request.url?.includes("lab-type-chart/export-data/?")) {
      request.responseType = "blob";
    }

    if (
      request.url?.includes("lab-type-chart/export-combined-report-excel/?")
    ) {
      request.responseType = "blob";
    }

    if (request.url?.includes("lab-type-chart/export-combined-report-pdf/?")) {
      request.responseType = "blob";
    }
    if (request.url?.includes("report-by-country-download/?")) {
      request.responseType = "blob";
    }
    if (request.url?.includes("report-by-top-ten-download/?")) {
      request.responseType = "blob";
    }
    if (request.url?.includes("report-by-region-download/?")) {
      request.responseType="blob";
    }
    if (request.url?.includes("report-by-department-download/?")) {
      request.responseType="blob";
    }
  }
  return request;
};
NetworkService.interceptors.request.use(requestHandler);

//response interceptor//
const errorHandler = (error) => {
  store.dispatch(globalSpinner(false)); //hide global spinner
  //if 401 then logout//
  if (error?.response?.status === 401) {
    setTimeout(() => {
      historyServiceObj.ssoReLogin(window.location.pathname);
    }, 1);
  } else if (error?.response?.status === 403) {
    const userInfo = _.isEmpty(
      JSON.parse(localStorage.getItem(OKTA_TOKEN_INFO))
    )
      ? JSON.parse(sessionStorage.getItem(LOGGED_IN_USER_INFO))
      : JSON.parse(localStorage.getItem(OKTA_TOKEN_INFO));
    toast.error(error?.response?.data?.detail, { toastId: 403 });
    if (userInfo?.access_token || userInfo?.accessToken) {
      historyServiceObj.redirect("/labs");
    } else {
      historyServiceObj.redirect("/");
    }
  } else if (error?.response?.status === 404) {
    const sessionPattern = /\/session\/\d+/;
    if (sessionPattern.test(error?.request?.responseURL) !== true) {
      store.dispatch(showBanner({
        payload: {
          showBanner: true,
          label: { message: ERROR_MESSAGE_UNKNOWN },
          variant: "error"
        }
      }))
    }


  } else if (error?.response?.data?.detail) {
    store.dispatch(
      showBanner({
        payload: {
          showBanner: true,
          label: { message: error?.response?.data?.detail },
          variant: "error",
        },
      })
    );
  } else if (error?.response?.data?.message) {
    store.dispatch(
      showBanner({
        payload: {
          showBanner: true,
          label: { message: error?.response?.data?.message },
          variant: "error",
        },
      })
    );
  }

  if (error.request.responseType === "arraybuffer") {
    //in case of zip api fails then read the data from array buffer
    const errorData = JSON.parse(
      Buffer.from(error.response.data).toString("utf8")
    );
    toast.error(errorData?.message);
    return Promise.reject(errorData);
  } else {
    //for rest all json or blob responses
    return Promise.reject({ ...error?.response?.data });
  }
};

const successHandler = (response) => {
  store.dispatch(globalSpinner(false)); //hide global spinner
  return response;
};
NetworkService.interceptors.response.use(successHandler, errorHandler);

export default NetworkService;
