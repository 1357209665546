import { HvDonutChart } from "@hitachivantara/uikit-react-viz";
import { HvBarChart } from "@hitachivantara/uikit-react-viz";
import _ from "lodash";
import {
  HvDatePicker,
  HvDropdown,
  HvGrid,
  HvTypography,
  HvPanel,
  HvDropDownMenu,
  HvEmptyState
} from "@hitachivantara/uikit-react-core";
import {
  HvContainer,
  HvSection,
} from "@hitachivantara/uikit-react-core";
import { Info } from "@hitachivantara/uikit-react-icons";
import ReportService from "services/ReportService.js";
import React, { useEffect, useState } from "react";
import { buildQueryParams } from "utils/common.js";
import moment from "moment";
import { REPORT_SIZE_OPTIONS } from "constants/labs";
import TableViewRegion from "./TableViewRegion";

const reportsServiceObj = new ReportService();
function APAC() {
  const defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate() - 30);
  const [startDate, setStartDate] = useState(defaultDate);
  const [endDate, setEndDate] = useState(new Date());
  const [pageLimit, setPageLimit] = useState(REPORT_SIZE_OPTIONS[0]);
  const [pageOffset, setPageOffset] = useState(0);
  const [selectedRadioButton, setSelectedRadioButton] = useState(0);
  const [userRegion, setUserRegion] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState();
  const [barChartData, setBarChartData] = useState([]);
  const [dounetChartData, setDounetChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
 
  const labels = {
    applyLabel: "Apply",
    cancelLabel: "Cancel",
  };

  function roundToTwoDecimalsAndAdjust(percentages) {
    let rounded = percentages.map(num => Math.round(num * 100) / 100);
    let total = rounded.reduce((acc, val) => acc + val, 0);
    total = Math.round(total * 100) / 100;
    let diff = Math.round((100 - total) * 100) / 100;

    while (diff !== 0) {
      for (let i = 0; i < rounded.length; i++) {
        if (diff > 0 && rounded[i] < 100) {
          rounded[i] = Math.round((rounded[i] + 0.01) * 100) / 100;
          diff = Math.round((diff - 0.01) * 100) / 100;
        } else if (diff < 0 && rounded[i] > 0) {
          rounded[i] = Math.round((rounded[i] - 0.01) * 100) / 100;
          diff = Math.round((diff + 0.01) * 100) / 100;
        }
        if (diff === 0) break;
      }
    }

    return rounded;
  }
  //selected date range
  const getReportByCustomDateRange = (startDate, endDate) => {
    let params = {};
    params["start_date"] = moment(startDate).format("YYYY-MM-DD");
    params["end_date"] = moment(endDate).format("YYYY-MM-DD");
    if (selectedRegion !== "All") {
      params["region"] = selectedRegion
    }
    reportsServiceObj
      .getUserRegionChartData(buildQueryParams(params))
      .then((response) => {
        if (response) {
          if(response?.total_session?.length > 0){
            const barReportData = [{
              total_session: response.total_session,
              region: response.region,
              percentage: response?.percentage?.length !== 0 ? roundToTwoDecimalsAndAdjust(response?.percentage) : []
            }]
            setBarChartData(barReportData);
          }else{
            setBarChartData([]);
          }
          
        }
      });

    if (_.isEmpty(userRegion)) {
      reportsServiceObj.getUserRegions().then((response) => {
        if (response) {
          const allRegions = response?.map((item) => {
            if (!_.isNull(item) && !_.isEmpty(item)) {
              return (
                {
                  label: item,
                  selected: false,
                  value: item
                }
              )
            }
          })
          allRegions?.unshift({ "label": "All", "selected": false, "value": "All" });
          setUserRegion(allRegions.filter(item => item !== undefined))
        }
      })
    }
  };

  //export data to excel
  const getExcelLabReport = () => {
    let tableParams = {};
    tableParams["start_date"] = moment(startDate).format("YYYY-MM-DD");
    tableParams["end_date"] = moment(endDate).format("YYYY-MM-DD");
    if (selectedRegion !== "All") {
      tableParams["region"] = selectedRegion
    }
    reportsServiceObj.getUserRegionDownload(buildQueryParams(tableParams))
      .then((response) => {
        // Create a URL for the Blob
        // const blob = response.blob();
        const url = window.URL.createObjectURL(response);

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = "lab_report.xlsx"; // Set the desired filename

        // Append the link to the DOM and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the URL
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching Excel data:", error);
      });
  };

  //export data to PDF
  const getPDFLabReport = () => {
    let tableParams = {};
    tableParams["start_date"] = moment(startDate).format("YYYY-MM-DD");
    tableParams["end_date"] = moment(endDate).format("YYYY-MM-DD");
    tableParams["file_format"] = "pdf";
    if (selectedRegion !== "All") {
      tableParams["region"] = selectedRegion
    }
    reportsServiceObj.getUserRegionDownload(buildQueryParams(tableParams))
      .then((response) => {
        // Create a URL for the Blob
        const url = window.URL.createObjectURL(response);

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = "lab_report.pdf"; // Set the desired filename

        // Append the link to the DOM and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the URL
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching PDF data:", error);
      });
  };

  useEffect(() => {
    getReportByCustomDateRange(startDate, endDate);
  }, [pageLimit, pageOffset, selectedRegion]);

  return (
    <>
      <HvContainer>
        <HvPanel>
          <HvTypography variant="xsTitle">
            Labs Details Report - By Region
          </HvTypography>
        </HvPanel>
        <HvGrid
          container
          spacing={2}
          rowSpacing={5}
          style={{ paddingTop: "50px", paddingLeft: "25px" }}
        >
          <HvGrid
            item
            xs={1}
            sm={1}
            md={2}
            lg={1}
            xl={2}
            style={{ display: "contents", paddingLeft: "15px" }}
          >
            <HvTypography
              variant="highlightText"
              style={{ alignSelf: "center" }}
            >
              Select Dates:
            </HvTypography>
          </HvGrid>

          <HvGrid
            item
            tem
            xs={2}
            sm={2}
            md={4}
            lg={2}
            xl={2}
            style={{ paddingTop: "5px", paddingRight: "10px" }}
          >
            <HvDatePicker
              id="DatePicker"
              aria-label="Date"
              placeholder="Select a range"
              labels={labels}
              showClear
              rangeMode
              calendarProps={{
                maximumDate: new Date(),
              }}
              startValue={startDate}
              endValue={endDate}
              onChange={(startDate, endDate) => {
                setStartDate(startDate);
                setEndDate(endDate);
                getReportByCustomDateRange(startDate, endDate);
              }}
              onClear={() => {
                setBarChartData([]);
                setDounetChartData([]);
                setTableData([]);
              }}
            />
          </HvGrid>
            
          <HvGrid
                item
                xs={1}
                sm={1}
                md={2}
                lg={1}
                xl={2}
                style={{ display: "contents", paddingLeft: "15px" }}
              >
                <HvTypography
                  variant="highlightText"
                  style={{ alignSelf: "center" }}
                >
                  Select Region:
                </HvTypography>
              </HvGrid>
              <HvGrid
                item
                tem
                xs={2}
                sm={2}
                md={4}
                lg={2}
                xl={2}
                style={{ paddingTop: "5px" }}
              >
                <HvDropdown
                  key={"country"}
                  aria-label="With search"
                  onChange={(valueObject) => {
                    setSelectedRegion(valueObject?.value)
                    setUserRegion(userRegion.map((item) => {
                      if (item.label === valueObject?.value) {
                        return { ...item, selected: true }
                      } else {
                        return { ...item, selected: false }
                      }
                    }))
                  }}
                  values={userRegion}
                />
              </HvGrid>
          {barChartData?.length > 0 ? (
            <HvGrid
              item
              xs={1}
              sm={1}
              md={2}
              lg={1}
              xl={2}
              style={{ position: "absolute", top: 125, right: 100 }}
            >
              {selectedRadioButton === 1 ? (
                <>
                  <HvDropDownMenu
                    dataList={[
                      {
                        label: "Download Excel Report",
                      },
                      {
                        label: "Download PDF Report",
                      },
                    ]}
                    //defaultExpanded
                    placement="left"
                    size="md"
                    variant="secondaryGhost"
                    onClick={(e, value) =>
                      value.label === "Download Excel Report"
                        ? getExcelLabReport()
                        : getPDFLabReport()
                    }
                  />
                </>
              ) : (
                <>
                  <HvDropDownMenu
                    dataList={[
                      {
                        label: "Download Excel Report",
                      },
                      {
                        label: "Download PDF Report",
                      },
                    ]}
                    //defaultExpanded
                    placement="left"
                    size="md"
                    variant="secondaryGhost"
                    onClick={(e, value) =>
                      value.label === "Download Excel Report"
                        ? getExcelLabReport()
                        : getPDFLabReport()
                    }
                  />
                </>
              )}
            </HvGrid>
          ) : (
            ""
          )}
        </HvGrid>

        <HvGrid container style={{ paddingTop: "30px" }}>
          {barChartData?.length > 0 ? (
            <>
              <HvGrid
                item
                xs={4}
                sm={6}
                md={4}
                lg={6}
                xl={6}
                style={{ paddingTop: "60px" }}
              >
                <HvSection
                  key={"bar-0"}
                  title={
                    <HvTypography variant="label">
                      Lab Usage By Region
                    </HvTypography>
                  }
                >
                  <HvBarChart
                    key={"bar-0"}
                    data={{
                      Session: barChartData[0]["total_session"],
                      region: barChartData[0]["region"],
                    }}
                    groupBy={"region"}
                    measures={"Session"}
                    stack="total"
                    horizontal
                    title="Lab Usage By Region"
                    height={400}
                    yAxis={{
                      type: "categorical",
                      position: "left",
                      label: {
                        formatter: function (value) {
                          return value.split(" ").join("\n");
                        },
                      },
                    }}
                    grid={{ left: 100 }}
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      whiteSpace: "normal",
                    }}
                  
                    onOptionChange={(option) => {
                      if (
                        Array.isArray(option.yAxis) &&
                        option.yAxis.length === 1
                      ) {
                        option.yAxis = [
                          {
                            ...option.yAxis[0],
                            axisLabel: {
                              ...option.yAxis[0].axisLabel,
                              width: 90,
                              overflow: "truncate",
                            },
                          },
                        ];
                      }
                      option.dataZoom = barChartData[0]?.total_session?.length > 10 ? [
                          {
                            type: 'slider',
                            yAxisIndex: 0,
                            start: 0,
                            end: barChartData[0]?.total_session?.length > 10 ? 10 : 100, // Show the first 10 items initially
                            labelFormatter: '' // Set to empty to remove the labels
                          }
                        ] : []

                      option.graphic = barChartData[0]?.total_session.length === 0 ? {
                        type: 'text',
                        left: 'left',
                        top: 'middle',
                        style: {
                          text: 'No data',
                          fontSize: 30,
                          fill: '#aaa',
                        },
                      } : {}

                      return option;
                    }}
                  />
                </HvSection>
              </HvGrid>
            </>
          ) : (
            <></>
          )}
          {barChartData.length > 0 ? (
            <>
              <HvGrid
                item
                xs={4}
                sm={4}
                md={4}
                lg={6}
                xl={6}
                style={{ paddingTop: "60px" }}
              >
                <HvSection
                  key={"donut-2"}
                  classes={{
                    content: "css-10klw3m",
                  }}
                  title={
                    <HvTypography variant="label">
                      Lab Usage By Region (%)
                    </HvTypography>
                  }
                >
                  <HvDonutChart
                    key={"donut-2"}
                    data={{
                      percentage: barChartData[0]["percentage"],
                      region: barChartData[0]["region"],
                    }}
                    groupBy={"region"}
                    measures={"percentage"}
                    type="regular"
                    title="Lab Usage by Region"
                    height={400}
                    width={700}
                    measure={{
                      field: "percentage",
                    }}
                    legend={{
                      direction: "vertical",
                      position: {
                        x: "left",
                        y: "top",
                      },
                    }}
                    onOptionChange={(option) => {
                      option.series = [{
                        type: 'pie',
                        radius: ['50%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                          show: false,
                          position: 'center'
                        }
                      }]
                      option.legend = {
                        orient: 'vertical',
                        type: 'scroll',
                        left: 'left',  // Position the legend to the left
                        top: 'middle'  // Center the legend vertically
                      }
                      option.tooltip = {
                        confine: true,
                        formatter: '{b} : {d}%'
                      }
                      return option;
                    }}
                  />
                </HvSection>
              </HvGrid>
            </>
          ) : (
            <></>
          )}
        </HvGrid>
        <HvGrid container style={{ paddingTop: "50px" }} columnGap={10}>
          {barChartData?.length > 0 ? (
            <>
              <HvGrid item xs={4} sm={8} md={8} lg={12} xl={12}>
                  <TableViewRegion startDate={moment(startDate).format("YYYY-MM-DD")}
                  endDate={moment(endDate).format("YYYY-MM-DD")} region={selectedRegion}/>
              </HvGrid>
            </>
          ) : (
            <></>
          )}
        </HvGrid>
        {
          barChartData?.length === 0 ? <HvEmptyState
            icon={<Info />}
            message="No data to display."
          /> : <></>
        }
      </HvContainer>
    </>
  );
}

export default APAC;
