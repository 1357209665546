import { HvButton, HvPanel, HvStack, HvTab, HvTabs, HvTypography, HvGrid, HvTag, HvOverflowTooltip, HvLabel, HvProvider } from '@hitachivantara/uikit-react-core';
import React, { useEffect, useState, useRef } from 'react';
import { PlayVideoScreen, Backwards, Time, Layers, Calendar, Tag, Host } from "@hitachivantara/uikit-react-icons";
import { LOGGED_IN_USER_INFO, VIRTUAL_HARDWARE, PHYSICAL_HARDWARE } from "constants/common";
import { Box } from '@mui/material';
import LabService from 'services/LabService';
import { buildQueryParams } from 'utils/common';
import ScPortal from 'components/newScPortal/ScPortal';
import _ from 'lodash';
import Resource from './Resource';
import { labEnvironmentId, showLoader } from "redux/actions/commonActions";
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { SAAS_LAB, SAAS_LAB_MESSAGE, LAB_AS_A_SERVICE, LAB_ON_DEMAND_MESSAGE, LAB_SERVICE_MESSAGE, ON_DEMAND_LAB, PREP_LAB, PREP_LAB_ONDEMAND } from 'constants/labs';
import FileUploadService from 'services/FileUploadService';
import { OKTA_TOKEN_INFO } from 'constants/common';
import { showBanner } from 'redux/actions/bannerActions';
import AgreementDialog from 'components/agreementDialog/AgreementDialog';
import { labInfo } from 'redux/actions/labActions';
import { useSelector } from 'react-redux';

const fileUploadServiceObj = new FileUploadService();

const LabDetailView = ({ history }) => {
    const labServiceObj = new LabService();
    const dispatch = useDispatch();
    const { location } = history;
    const state = location && location?.state;
    const labId = state?.labId ? state?.labId : location?.search?.split("=")[1]
    const [environment_id, setEnvironment_id] = useState(state?.environment_id ? state?.environment_id : "");
    const used_time = state?.used_time ? state?.used_time : 0;
    const extended_count = state?.extended_count ? state?.extended_count : 0;
    const url_end_time = state?.url_end_time ? state?.url_end_time : 0;
    const session_end_time = state?.session_end_time ? state?.session_end_time : 0;
    const extended_minutes = state?.extended_minutes ? state?.extended_minutes : 0
    const co_creation_lab = state?.co_creation_lab ? state?.co_creation_lab : false
    const provider = state?.provider ? state?.provider : ""
    const [tabsName, setTabsName] = useState(['Overview', 'Solution Engineering Resources', 'Global Learning Resources']);
    const [labDetail, setLabDetail] = useState()
    const [value, setValue] = useState(0);
    const [runningLabData, setRunningLabData] = useState();
    const [showSCPortal, setShowSCPortal] = useState(false);
    const [resourceData, setResourceData] = useState([]);
    const [globalResourceData, setGlobalResourceData] = useState([]);
    const [activeSession, setActiveSession] = useState([]);
    const [openAgreementDialog, setOpenAgreementDialog] = useState(false);
    const [acceptAggreement, setAcceptedAgreement] = useState(false);
    const [tabName, setSelectedTabName] = useState("Overview");
    const labType = state?.lab_type

    const handleChange = (e, newValue, tabName) => {
        setSelectedTabName(tabName)
       setValue(newValue)
    }
    const containerRef = useRef(null);

    let data = sessionStorage.getItem(LOGGED_IN_USER_INFO);
    let token = data ? JSON.parse(data) : {}
    const hasCustomer = token?.groups?.map(item => item.name === "Customers");
    const isCustomer = hasCustomer?.includes(true);
    let urlParams = {};
    let oktaUserInfo = JSON.parse(localStorage.getItem(OKTA_TOKEN_INFO))
    useEffect(() => {
        containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        if (!_.isUndefined(labId)) {
            getLabDetailsByURLId();
        } else {

        }
        if (isCustomer) {
            setTabsName(['Overview', 'Global Learning Resources'])
        }
        if(labType === SAAS_LAB){
            setTabsName(['Overview', 'Global Learning Resources',"Field Resources"])
        }
       
    }, [labId]);

    const getLabDetailsByURLId = () => {
        let browserURL = window.location.href;
        if (labId && (browserURL.search("labId") < 0 || (token?.access_token) || oktaUserInfo?.accessToken?.accessToken)) {
            localStorage.setItem("LabUrl", '/labdetailview/?labId=' + labId)
            urlParams["object_id"] = labId;
            fileUploadServiceObj.getResourceUrlByLabId(buildQueryParams(urlParams)).then(response => {
                if (response) {
                    const { results } = response;
                    if (results && results.length > 0) {
                        setResourceData(results)

                    }
                }
            }).catch(error => {
                dispatch(showBanner({
                    payload: {
                        showBanner: true,
                        label: { message: "Error while getting resource." },
                        variant: "error"
                    }
                }))
            })
            labServiceObj.getLabDetailsById(labId).then(response => {
                if (response) {
                    setLabDetail(response);
                    if (response?.session.length > 0) {
                        setActiveSession(response.session[0]);
                    }
                    localStorage.removeItem("LabUrl");
                }
            }).catch(error => {
                dispatch(showBanner({
                    payload: {
                        showBanner: true,
                        label: { message: "Error while getting Lab details. Please contact Administrator." },
                        variant: "error"
                    }
                }))
                history.push("/labs");
            })
            let params = {};
            params["is_deleted"] = false;
            params["lab"] = labId
            labServiceObj.runningLab(buildQueryParams(params)).then((response) => {
                if (response) {
                    setEnvironment_id(response?.results[0]?.enviroment_id)
                }
            })
        } else {
            if ((_.isEmpty(token?.access_token) && _.isEmpty(oktaUserInfo?.accessToken?.accessToken))) {
                localStorage.setItem("LabUrl", '/labdetailview/?labId=' + labId)
                history.push("/");
            } else {
                if (browserURL.search("labId") > 0) {
                    localStorage.setItem("LabUrl", '/labdetailview/?labId=' + labId)
                }
            }
        }

    }



    const redirect = (url, labData) => {
        history.push(
            {
                pathname: url,
                state: {
                    labDetail: labData
                }
            }
        );
    }



    const displayTabs = tabsName.map((tab, index) => {
        return (
            <>
                <HvTab id={index} label={tab} value={index}  onChange={(e)=>handleChange(e, index, tab)} />
            </>
        )
    })

    const renderContainer = (id, children) => (
        <>
            <HvTypography id={"description"} variant='caption1'>
                <HvProvider rootElementId='description' cssBaseline='none'><div dangerouslySetInnerHTML={{ __html: children || "" }} /></HvProvider>
            </HvTypography>
        </>
    )

    const renderLabButton = (lab) => {
        switch (lab?.tag_description) {
            case ON_DEMAND_LAB:
                return (<>
                    <HvButton variant="primary" onClick={() => { fetchRedirectionUrlForLab(labDetail?.id, labDetail?.lab_type) }}><PlayVideoScreen />Run Lab</HvButton>
                </>)
            case PREP_LAB_ONDEMAND:
                return (<>
                    <HvButton variant="primary" onClick={() => { fetchRedirectionUrlForLab(labDetail?.id, labDetail?.lab_type) }}><PlayVideoScreen />Run Lab</HvButton>
                </>)
            case LAB_AS_A_SERVICE:
                return (<>
                    <HvButton variant="primary" onClick={() => { redirect('/labscheduler', labDetail) }}><Calendar />Schedule</HvButton>
                </>)
            default:
                return (<>
                    <HvButton variant="primary" onClick={() => { fetchRedirectionUrlForLab(labDetail?.id, labDetail?.lab_type) }}><PlayVideoScreen />Run Lab</HvButton>
                </>)
        }
    }

    const renderLabTypeIcon = (tag_description) => {
        switch (tag_description) {
            case ON_DEMAND_LAB:
                return (
                    <>
                        <Box component="div" display='flex' width='55%'>
                            <Host size="S" />
                            <HvTypography variant="caption1" style={{ alignSelf: 'center' }}>{VIRTUAL_HARDWARE}</HvTypography>
                        </Box>
                    </>
                )
            case PREP_LAB_ONDEMAND:
                return (
                    <>
                        <Box component="div" display='flex' width='55%'>
                            <Host size="S" />
                            <HvTypography variant="caption1" style={{ alignSelf: 'center' }}>{VIRTUAL_HARDWARE}</HvTypography>
                        </Box>
                    </>
                )
            case LAB_AS_A_SERVICE:
                return (
                    <>
                        <Box component="div" display='flex' width='55%'>
                            <Host size="S" />
                            <HvTypography variant="caption1" style={{ alignSelf: 'center' }}>{PHYSICAL_HARDWARE}</HvTypography>
                        </Box>
                    </>
                )

            default:
                return (
                    <>
                        <Box component="div" display='flex' width='55%'>
                            <Host size="S" />
                            <HvTypography variant="caption1" style={{ alignSelf: 'center' }}>NA</HvTypography>
                        </Box>
                    </>
                )
        }
    }

    const fetchRedirectionUrlForLab = (id, lab_type) => {

        const data = sessionStorage.getItem(LOGGED_IN_USER_INFO);
        const token = data ? JSON.parse(data) : {}
        const urlParams = { lab_id: labDetail?.id }
        let finalData = {};
        if (token?.access_token) {
            if (!_.isEmpty(activeSession)) {
                let data = {
                    id: activeSession?.id,
                    url: activeSession?.url,
                    labId: labDetail?.id,
                    labName: labDetail?.name,
                    environment_id: environment_id,
                    lab_type: labDetail?.lab_type,
                    used_time: used_time,
                    extended_count: extended_count,
                    url_end_time: url_end_time,
                    extended_minutes: extended_minutes,
                    co_creation_lab: co_creation_lab,
                    session_end_time: session_end_time,
                    provider: provider
                }

                dispatch(showLoader(true));
                dispatch(labEnvironmentId(environment_id));
                sessionStorage.setItem("environmentId", environment_id)
                dispatch(labInfo({ ...labDetail, ...{ used_time: used_time, extended_count: extended_count, url_end_time: url_end_time, lab_type: labDetail?.lab_type, session_end_time: session_end_time, provider: provider } }));
                labServiceObj.getInstruction(buildQueryParams(urlParams)).then(response => {
                    setShowSCPortal(!showSCPortal)
                    if (response) {
                        dispatch(showLoader(false))
                        finalData = {
                            ...data,
                            instruction: response?.data.results[0]?.instruction_file,
                            video_instruction: response?.data.results[0]?.video_instruction
                        }
                    }

                    redirect('/scportal', finalData);
                    const labData = [];
                    labData.push(finalData);
                })
            } else {
                const payload = { "lab": labDetail?.id };
                dispatch(showLoader(true))
                labServiceObj.activateLabUrl(payload).then(response => {
                    if (response && (response.url !== '' && response.url !== null)) {
                        let data = {
                            url: response.url,
                            labId: labDetail?.id,
                            labName: labDetail?.name,
                            id: response.id,
                            environment_id: response?.enviroment_id,
                            lab_type : response?.lab_type
                        }
                        dispatch(labEnvironmentId(response?.enviroment_id));
                        sessionStorage.setItem("environmentId", response?.enviroment_id)
                        dispatch(labInfo(response));
                        labServiceObj.getInstruction(buildQueryParams(urlParams)).then(response => {
                            setShowSCPortal(!showSCPortal)
                            if (response) {
                                dispatch(showLoader(false))
                                finalData = {
                                    ...data,
                                    instruction: response?.data.results[0]?.instruction_file,
                                    video_instruction: response?.data.results[0]?.video_instruction
                                }
                            }
                            redirect('/scportal', finalData);
                            const labData = [];
                            labData.push(finalData);
                        })
                        setRunningLabData(finalData)
                    }
                })
                    .catch(() => {
                        dispatch(showBanner({
                            payload: {
                                showBanner: true,
                                label: { message: "Error while running the lab." },
                                variant: "error"
                            }
                        }))
                    });
            }

        } else {
            redirect('/')
        }

    }

    const calCulateHourAndTime = (labTime) => {
        const hours = Math.floor(labTime / 60);
        const minutes = labTime % 60;
        return minutes > 0 ? `${hours}hrs and ${minutes}mins.` : `${hours}hrs.`

    }

    const renderSubHeader = (lab) => {
        switch (lab?.tag_description) {
            case ON_DEMAND_LAB:
                return (
                    <><HvTypography variant='title2' style={{ display: "flex" }}> <Backwards onClick={() => { history?.push("/labs") }} style={{ cursor: "pointer" }} />{labDetail?.name}</HvTypography>
                        <HvTypography variant='selectedNavText' style={{ display: "flex", padding: "10px" }} >{LAB_ON_DEMAND_MESSAGE} </HvTypography></>
                )
            case LAB_AS_A_SERVICE:
                return (
                    <><HvTypography variant='title2' style={{ display: "flex" }}> <Backwards onClick={() => { history?.push("/labs") }} style={{ cursor: "pointer" }} />{labDetail?.name}</HvTypography>
                        <HvTypography variant='selectedNavText' style={{ display: "flex", padding: "10px" }} >{LAB_SERVICE_MESSAGE} </HvTypography></>
                )

            case PREP_LAB_ONDEMAND:
                return (
                    <><HvTypography variant='title2' style={{ display: "flex" }}> <Backwards onClick={() => { history?.push("/labs") }} style={{ cursor: "pointer" }} />{labDetail?.name}</HvTypography>
                        <HvTypography variant='selectedNavText' style={{ display: "flex", padding: "10px" }} >Prep Lab :{LAB_ON_DEMAND_MESSAGE} </HvTypography></>
                )
            case SAAS_LAB:
                return (
                    <><HvTypography variant='title2' style={{ display: "flex" }}> <Backwards onClick={() => { history?.push("/labs") }} style={{ cursor: "pointer" }} />{labDetail?.name}</HvTypography>
                        <HvTypography variant='selectedNavText' style={{ display: "flex", padding: "10px" }} >{SAAS_LAB_MESSAGE} </HvTypography></>
                )
            default:
                return (
                    <><HvTypography variant='title2' style={{ display: "flex" }}> <Backwards onClick={() => { history?.push("/labs") }} style={{ cursor: "pointer" }} />{labDetail?.name}</HvTypography>
                        <HvTypography variant='selectedNavText' style={{ display: "flex", padding: "10px" }} >{LAB_ON_DEMAND_MESSAGE} </HvTypography></>
                )
        }
    }

    function convertTo12HourFormat(timestamp) {
        const date = new Date(timestamp);

        // Extracting date components
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        // Extracting time components
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Converting hours to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // 0 should be converted to 12

        // Formatting into a single string
        const formattedDateTime = `${month}/${day}/${year}`;

        return formattedDateTime;
    }
    return (
        <div ref={containerRef} id="labdetailview"  >
            {
                showSCPortal ?
                    <ScPortal data={runningLabData} /> :
                    <HvStack style={{ paddingTop: "10px" }} ref={containerRef}>
                        <HvPanel >
                            <HvGrid container={true}>
                                <HvGrid item xs={4} sm={8} md={8} lg={6} xl={12}>
                                    <Box>
                                        {renderSubHeader(labDetail)}
                                    </Box>
                                </HvGrid>
                                <HvGrid item xs={6} sm={4} md={6} lg={4} xl={6}>
                                    <Box component={'div'} columnGap={2} >
                                        {
                                            labDetail?.max_lab_time ?
                                                <Box component={'div'} display={'flex'} alignSelf={'center'} >
                                                    <Time />
                                                    <HvTypography variant="caption1" style={{ alignSelf: 'center' }}>{labDetail?.max_lab_time && !_.isNull(labDetail?.max_lab_time) ? (labDetail?.max_lab_time < 60 ? `${labDetail.max_lab_time} minutes` : calCulateHourAndTime(labDetail?.max_lab_time)) : 'NA'}</HvTypography>
                                                </Box> : <Box component={'div'} display={'flex'} alignSelf={'center'} >
                                                    <Time />
                                                    <HvTypography variant="caption1" style={{ alignSelf: 'center' }}>{"Self Paced"}</HvTypography>
                                                </Box>
                                        }
                                    </Box>
                                    <Box component={'div'} display={'flex'} alignSelf={'center'}  >
                                        <Layers />
                                        <HvTypography variant="caption1" style={{ alignSelf: 'center' }}>{!_.isEmpty(labDetail?.categories) ? labDetail?.categories.toString() : "NA"}</HvTypography>
                                    </Box>
                                    <Box component={'div'} display={'flex'} alignSelf={'center'} style={{ flexWrap: "wrap" }}>
                                        {labDetail?.labtags && labDetail?.labtags.length > 0 ?
                                            <>

                                                <Tag size="S" />
                                                {
                                                    labDetail?.labtags.map((t1) => (
                                                        <>
                                                            <HvTag  size='medium' label={t1.length > 4 ? <HvOverflowTooltip data={t1}></HvOverflowTooltip> : t1} type="categorical" color={_.sample(["cat2", "cat3", "cat4", "cat5", "cat6"])} style={{ alignSelf: 'center' }} />
                                                        </>
                                                    ))
                                                }

                                            </>

                                            :
                                            <></>}
                                    </Box>
                                    <Box component="div" display='flex' >
                                        {
                                            renderLabTypeIcon(labDetail?.tag_description)
                                        }
                                    </Box>
                                </HvGrid>
                                <HvGrid item xs={4} sm={4} md={4} lg={2} xl={6} ref={containerRef} >
                                    {
                                        !_.isEmpty(activeSession) & labDetail?.maintenance === false ?
                                            <Box component={'div'} alignSelf={'center'}>
                                                <HvButton variant="primary" onClick={() => { fetchRedirectionUrlForLab(labDetail?.id) }}><PlayVideoScreen />View Lab</HvButton>
                                            </Box> :
                                            <Box component={'div'} alignSelf={'center'}>
                                                {
                                                    labDetail?.maintenance === true ? <HvTypography variant="selectedNavText">This lab is under maintenance till {convertTo12HourFormat(labDetail?.maintenance_end)} .</HvTypography> : renderLabButton(labDetail)
                                                }
                                            </Box>
                                    }

                                </HvGrid>
                            </HvGrid>
                        </HvPanel>
                        <HvPanel>
                            <HvTabs id='tabs-main' value={value}  variant="fullWidth" onChange={(e)=>{handleChange(e,value)}}>
                                {displayTabs}
                            </HvTabs>
                            <HvPanel id='TabsPanel' style={{ paddingTop: "30px" }}>
                                {tabName === "Overview" && renderContainer("overview", labDetail?.description)}
                                {tabName === "Solution Engineering Resources" && <><Resource resourceData={resourceData} objectId={labId} resourcesCategory="Normal" /></> }
                                {tabName === "Global Learning Resources" && <><Resource resourceData={resourceData} objectId={labId} resourcesCategory="Global Learning" /></>}
                                {tabName === "Field Resources" && <><Resource resourceData={resourceData} objectId={labId} resourcesCategory="Field Resources" /></>}
                            </HvPanel>
                        </HvPanel>
                    </HvStack>

            }
            {
                openAgreementDialog ? <AgreementDialog launchOrScheduleLab={(value) => { setAcceptedAgreement(value) }} openAgreementDialog={(value) => { setOpenAgreementDialog(value) }} /> : <></>
            }
        </div>
    );
};



export default withRouter(LabDetailView);